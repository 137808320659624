import React from "react";
import {  navigate } from "gatsby";

const thankYou = () => {

    const backToCollections = () =>{
        navigate("/products/collections");
    }
  return (
    <div className="waitlist-thank-you">
      <div className="body">
        <header>Got it!</header>
        <div className="desc">
          We'll notify you at the earliest time <br /> possible.
        </div>
        <button onClick={backToCollections}>Back to Collections page</button>
      </div>
    </div>
  );
};

export default thankYou;
